#root {
  overflow-x: hidden;
}

/* Common */
.divider {
  width: 100%;
  height: 1px;
  margin-top: 20px;
  background: rgb(204, 204, 204);
}

.desktop-hidden {
  display: none;
}

.dark-background {
  position: fixed;
  height: 0px;
  width: 0px;
  background: rgba(0, 0, 0, 0.678);
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  transition: opacity 0.5s;
  opacity: 0;
}

.dark-background-active {
  opacity: 1;
  height: 100vh !important;
  width: 100vw !important;
  z-index: -1 !important;
}

.laptop-visible {
  display: none;
}

@media screen and (max-width: 1400px) {
  .laptop-visible {
    display: block;
  }
}

@media screen and (max-width: 880px) {
  .mobile-hidden {
    display: none;
  }
  .desktop-hidden {
    display: block;
  }
}

/* Navbar */
.header {
  position: relative;
  justify-content: space-between;
}

.header-logo-container {
  position: absolute;
  width: 100%;
  z-index: -1;
}

.navbar-options-container {
  height: 15rem;
  display: flex;
  flex-direction: row;
}

.navbar-option {
  margin-top: auto;
  margin-bottom: auto;
}

.header-logo {
  margin-left: auto;
  margin-right: auto;
}

.extended-nav-container {
  position: absolute;
  top: 15rem;
  width: 100%;
  height: 10rem;
}

.extended-options-container {
  width: 60vw;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.extended-options {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  height: 100%;
}

.extended-option-container {
  display: flex;
  flex-direction: column;
  min-width: 10vw;
}

.extended-option:hover {
  color: rgb(94, 94, 94);
}

.menu-icon {
  display: none;
}

.sub-categories-container {
  display: none;
}

@media screen and (max-width: 1400px) {
  .header {
    justify-content: flex-start;
  }
  .navbar-options-container {
    flex-direction: column;
    position: fixed;
    left: -100%;
    background: white;
    height: 100vh;
    width: 100%;
    max-width: 350px;
    -webkit-transition: left 0.5s;
    -moz-transition: left 0.5s;
    -o-transition: left 0.5s;
    -ms-transition: left 0.5s;
    transition: left 0.5s;
  }
  .navbar-options-container-open {
    left: 0 !important;
  }
  .navbar-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .menu-icon {
    display: block;
  }
  .mobile-left {
    margin-left: unset;
  }
  .pull-right {
    margin-left: auto;
  }
  .mobile-width-full {
    width: 100%;
  }
  .sub-categories-container {
    display: block;
    max-height: 0;
    height: auto;
    -webkit-transition: max-height 0.25s ease-in-out;
    -moz-transition: max-height 0.25s ease-in-out;
    -o-transition: max-height 0.25s ease-in-out;
    -ms-transition: max-height 0.25s ease-in-out;
    transition: max-height 0.25s ease-in-out;
  }
  .sub-categories-container-open {
    max-height: 80px !important;
  }
}

/* Home */
@media screen and (max-width: 1400px) {
  .home-left {
    margin-top: 60px;
  }
}
@media screen and (min-width: 1921px) {
  .large-text {
    font-size: 80px;
  }
}
